.custom-row {
  background: rgba(0, 0, 0, 0.02);
}
.tox-promotion {
  display: none;
  opacity: 0;
}
.counter-value {
  .cho-duyet {
    .ant-statistic {
      .ant-statistic-content {
        .ant-statistic-content-value {
          color: #0AB39C !important;
          font-size: 40px !important;
          font-weight: 600;
          line-height: 48px;
        }
      }
    }
  }
}

.counter-value {
  .cho-xuat-ban {
    .ant-statistic {
      .ant-statistic-content {
        .ant-statistic-content-value {
          color: #EC4899 !important;
          font-size: 40px !important;
          font-weight: 600;
          line-height: 48px;
        }
      }
    }
  }
}

.counter-value {
  .da-xuat-ban {
    .ant-statistic {
      .ant-statistic-content {
        .ant-statistic-content-value {
          color: #F7B84B !important;
          font-size: 40px !important;
          font-weight: 600;
          line-height: 48px;
        }
      }
    }
  }
}

.counter-value {
  .ha-xuat-ban {
    .ant-statistic {
      .ant-statistic-content {
        .ant-statistic-content-value {
          color: #9333EA !important;
          font-size: 40px !important;
          font-weight: 600;
          line-height: 48px;
        }
      }
    }
  }
}