//
// Select 2
//

.select2.select2-container {
  width: 100% !important;
}
.select2-container {
  .select2-selection--single {
    border: $input-border-width solid $input-border-color;
    height: $input-height;
    background-color: $input-bg;
    outline: none;
    .select2-selection__rendered {
      line-height: 36px;
      padding-left: 12px;
      color: $input-color;
    }
    .select2-selection__arrow {
      height: 34px;
      width: 34px;
      /*rtl:ignore*/
      right: 3px;
      b {
        border-color: $input-border-color transparent transparent transparent;
        border-width: 6px 6px 0 6px;
      }
    }
  }
}

.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      b {
        border-color: transparent transparent $input-border-color transparent !important;
        border-width: 0 6px 6px 6px !important;
      }
    }
  }
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: $dropdown-border-width solid $dropdown-border-color;
  box-shadow: $box-shadow;
  background-color: var(--#{$prefix}choices-bg);
  z-index: $zindex-modal + 1;
}

.select2-container--default {
  .select2-results__option--selected {
    background-color: $input-disabled-bg;
  }
  .select2-search--dropdown {
    padding: 10px;
    background-color: var(--#{$prefix}choices-bg);
    .select2-search__field {
      outline: none;
      border: 1px solid $input-border-color;
      background-color: $input-bg;
      color: $input-color;
      border-radius: $input-border-radius;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
  }
  .select2-results__option[aria-selected="true"] {
    background-color: var(--#{$prefix}choices-bg);
    color: var(--#{$prefix}choices-link-active-color);
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice__display {
      padding-left: 36px;
      padding-right: 5px;
    }
  }
  .select2-selection--single {
    .select2-selection__arrow {
      /*rtl:ignore*/
      right: 1px;
      /*rtl:ignore*/
      left: auto;
    }
  }
}

.select2-container {
  .select2-selection--multiple {
    min-height: $input-height;
    border: $input-border-width solid $input-border-color !important;
    background-color: $input-bg;

    .select2-selection__rendered {
      padding: 1px 4px;
    }
    .select2-search__field {
      border: 0;
      color: $input-color;
    }
    .select2-selection__choice {
      background-color: $primary;
      border: none;
      color: $white;
      border-radius: 3px;
      padding: 3px;
      margin-top: 6px;
    }
    .select2-selection__choice__remove {
      color: $white;
      margin-right: 7px;
      border-color: tint-color($primary, 10%);
      padding: 0 8px;
      top: 3px;
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }
  .select2-search--inline {
    .select2-search__field {
      margin-top: 7px;
      font-family: $font-family-base;
    }
  }
  .select2-search {
    textarea {
      &::placeholder {
        color: $input-placeholder-color;
      }
    }
  }
}

.select2-container--default {
  &.select2-container--disabled {
    .select2-selection--single,
    .select2-selection--multiple {
      background-color: $input-disabled-bg;
      cursor: default;
    }
  }
}
