//
// _colorpicker.scss
//

.pcr-app {
  background: var(--#{$prefix}card-bg-custom);
  box-shadow: $box-shadow-lg;
  border-radius: 4px;
  border: 1px solid $border-color;
}

.pickr {
  .pcr-button {
    border: 4px solid var(--#{$prefix}card-bg-custom);
    box-shadow: 0px 0px 0 2px $border-color;
    border-radius: 50%;
    box-shadow: $element-shadow;
    &::after,
    &::before {
      border-radius: 50%;
    }
  }
}

.pcr-app {
  &[data-theme="classic"] {
    .pcr-selection {
      .pcr-color-preview {
        margin-right: 0.75em;
        margin-left: 0;
      }

      .pcr-color-chooser,
      .pcr-color-opacity {
        margin-left: 0.75em;
        margin-right: 0;
      }
    }
  }

  &[data-theme="monolith"] {
    .pcr-result {
      min-width: 100%;
    }
  }

  .pcr-interaction {
    .pcr-type.active {
      background: $primary;
    }

    .pcr-result {
      background-color: $input-bg;
      color: $input-color;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
    }

    input {
      border-radius: $input-border-radius !important;
      &:focus {
        box-shadow: none;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
      }
    }

    .pcr-save {
      background: $success !important;
    }

    .pcr-clear,
    .pcr-cancel {
      background: $danger !important;
    }
  }
}
