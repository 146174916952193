del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}
ins {
  text-decoration: none;
  background-color: #d4fcbc;
}
ins img {
  padding: 10px;
  background-color: #d4fcbc;
}

del img {
  padding: 10px;
  background-color: #fbb6c2;
}
.MenuSearchBox {
  position: fixed;
  bottom: 10px;
  right: 50px;
  display: flex;
  align-items: center;
}
.iconMenuBox {
}
.iconGoogleBoxSearch {
  display: none;
  width: 80px;
  height: 80px;
  object-fit: contain;
  cursor: pointer;
}
.iconChatgptBoxSearch {
  display: none;
  width: 80px;
  height: 80px;
  object-fit: contain;
  cursor: pointer;
}
.MenuSearchBox:hover .iconMenuBox {
  display: none;
}
.MenuSearchBox:hover .iconGoogleBoxSearch {
  display: flex;
}
.MenuSearchBox:hover .iconChatgptBoxSearch {
  display: flex;
}

.Msearch {
  z-index: 99999999;
  width: 875px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  position: absolute;
  right: 20px;
  bottom: 100%;
  padding: 14px;
  background: #fff;
  &1 {
    position: absolute;
    top: 11px;
    right: 17px;
    border: none;
    background: transparent;
    &-1 {
      width: 20px;
      height: 20px;
    }
  }
  &2 {
    font-size: 15px;
    color: #3668c9;
    font-weight: 500;
  }
  &3 {
    font-size: 15px;
    color: #333333;
    font-weight: 500;
  }
  &4 {
    position: relative;
    margin: 12px 0;
  }
  &5 {
    border: 1px solid #d5d5d5;
    font-size: 15px;
    color: #333333;
    font-weight: 500;
    padding-left: 12px;
    height: 40px;
    border-radius: 8px;
    width: 100%;
  }
  &6 {
    background: #0ab39c;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    color: #fff;
  }
  &7 {
    border-radius: 8px;
    border: 1px solid #d5d5d5;
  }
  &3-1 {
    padding-left: 12px;
    border-bottom: 1px solid #d5d5d5;
    height: 60px;
    display: flex;
    align-items: center;
  }
  &3-2 {
    margin-left: 4px;
  }
  &8 {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
    padding: 0 12px;
  }
  &10 {
    display: flex;
    align-items: center;
  }
  &11 {
    font-size: 15px;
    color: #000;
    font-weight: 300;
  }
  &12 {
    padding-right: 12px;
    border-right: 1px solid #000;
  }
  &13 {
    display: flex;
    align-items: center;
    margin: 0 -4px;
  }
  &14 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    width: 90px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 12px;
    color: #333333;
    height: 25px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }
  &16 {
    padding-left: 12px;
  }
}
.Mchat {
  z-index: 99999999;
  width: 875px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  position: absolute;
  right: 20px;
  bottom: 100%;
  padding: 14px;
  background: #fff;

  &2 {
    position: absolute;
    top: 16px;
    right: 17px;
    border: none;
    background: transparent;
    &-2 {
      width: 20px;
      height: 20px;
    }
  }
  &3 {
    display: flex;
    align-items: center;
    height: 49px;
    border: 1px solid #000;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 14px;
  }
  &4 {
    width: 30px;
    height: 30px;
  }
  &5 {
    font-size: 15px;
    color: #333333;
    font-weight: 500;
    margin-left: 12px;
  }
  &6 {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    background: #f2f2f2;
  }
  &8 {
    font-size: 10px;
    color: #333333;
    font-weight: 300;

    font-style: italic;
  }
  &9 {
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &10 {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    background: #fff;
    border: 1px solid #d5d5d5;
    justify-content: flex-end;
  }
  &12 {
    width: 50px;
    height: 50px;
    margin-left: 14px;
    margin-top: -5px;
  }
  &14 {
    width: 30px;
    height: 30px;
    margin-right: 14px;
  }
  &15 {
    margin-top: 6px;
    position: relative;
  }
  &16 {
    height: 40px;
    border: 1px solid #d5d5d5;
    background: #fff;
    border-radius: 8px;
    padding-left: 12px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 15px;
    color: #000;
    width: 100%;
    &::placeholder {
      color: #7e8cac;
    }
  }
  &17 {
    width: 30px;
    height: 30px;
  }
  &18 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    border: none;
    background: transparent;
  }
}
