.ant-table-row-custom {
  background-color: #ebedee;
  cursor: pointer;
}

.ant-table-row {
  cursor: pointer;
}

.preview-image {
  z-index: 2500;
}

.btn-transcode {
  border: 1px solid #ffffff;
  border-radius: 20px;
  width: 83px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 5px;
}
.close-btn-overview {
  color: rgb(255, 255, 255);
}
.close-btn-overview:hover {
  background-color: rgb(197 6 6) !important;
}
.button-delete-img-avatar {
  opacity: 0.8;
  border: none;
  border-radius: 50%;
  background-color: rgb(255, 0, 0);
  height: 42px;
  transform: scale(0.6);
  margin-bottom: -40px;
  z-index: 1;
}
.button-delete-img-avatar:hover {
  opacity: 1;
  background-color: rgb(255, 0, 0);
}
.apexcharts-legend-text {
  color: #000 !important;
  font-weight: 600 !important;
}
.app-short-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
