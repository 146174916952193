.hover-bubble {
  width: 30px;
  height: 30px;
  background: #ccc;
  border-radius: 50%;
  border-bottom-left-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    overflow-y: scroll;
    height: 200px;
    width: 300px;
    padding: 10px;
    justify-content: start;
    align-items: start;
    border-radius: 24px;
    border-bottom-left-radius: 0;
    & .hover-bubble3 {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }
  &2 {
    width: 27px;
    height: 27px;
    border-radius: 50%;
  }
  &3 {
    display: none;
  }
  &4 {
  }
  &5 {
  }
}
